<template>
  <div class="divBox">
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="100px"
      >
        <div style="width: 100%">
          <el-form-item label="订单状态：" prop="status">
            <el-radio-group
              v-model="tableFrom.status"
              type="button"
              @change="typeChange"
            >
              <div style="display: flex">
                <el-radio-button label=""
                  >全部{{ Count.count }}</el-radio-button
                >
                <el-radio-button label="0"
                  >待支付{{ Count.count0 }}</el-radio-button
                >
                <el-radio-button label="10"
                  >待发货{{ Count.count10 }}</el-radio-button
                >
                <el-radio-button label="11"
                  >部分发货{{ Count.count11 }}</el-radio-button
                >
                <el-radio-button label="20"
                  >待收货{{ Count.count20 }}</el-radio-button
                >
                <!-- <el-radio-button label="30">待评价</el-radio-button> -->
                <el-radio-button label="40"
                  >已完成{{ Count.count40 }}</el-radio-button
                >
                <el-radio-button label="90"
                  >已关闭{{ Count.count90 }}</el-radio-button
                >
                <el-radio-button label="100"
                  >已取消{{ Count.count100 }}</el-radio-button
                >
              </div>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item label="单号：" prop="orderCode">
          <el-input
            v-model="tableFrom.orderCode"
            placeholder="请输入单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="售后单号：" prop="refundCode">
          <el-input
            v-model="tableFrom.refundCode"
            placeholder="请输入售后单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="下单人：" prop="userName">
          <el-input
            v-model="tableFrom.userName"
            placeholder="请输入下单人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="下单人电话：" prop="phone">
          <el-input
            v-model="tableFrom.phone"
            placeholder="请输入下单人电话"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="收货人：" prop="shippingName">
          <el-input
            v-model="tableFrom.shippingName"
            placeholder="请输入收货人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="推广员：" prop="extensionName">
          <el-input
            v-model="tableFrom.extensionName"
            placeholder="请输入推广员"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="收货人电话：" prop="mobile">
          <el-input
            v-model="tableFrom.mobile"
            placeholder="请输入收货人电话"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            v-model="tableFrom.goodsName"
            placeholder="请输入商品名称："
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            class="input-with-select selWidth"
            v-model="tableFrom.time"
            value-format="yyyy/MM/dd"
            format="yyyy/MM/dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            type="daterange"
            placement="bottom-end"
            style="width: 280px"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button type="primary" size="small" @click="exportFun()"
            >导出</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card>
      <el-table
        :default-expand-all="true"
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        class="table"
        row-key="id"
      >
        <el-table-column type="expand">
          <template slot-scope="orderItems">
            <div class="orderItems">
              <div
                v-for="(item, index) in orderItems.row.orderItems"
                class="item"
                :key="index"
              >
                <img class="item_img" :src="item.goodsSkuImg" alt="" />
                <div>
                  <div class="item_name">{{ item.goodsName }}</div>
                  <div class="item_price">售价：￥{{ item.salePrice }}</div>
                  <div class="item_num supplier">
                    供应商：{{ item.supplierName }}
                  </div>
                  <div class="item_num">规格：{{ item.goodsSkuName }}</div>
                  <div class="item_num">数量：x{{ item.quantity }}</div>
                </div>
                <el-button
                  icon="el-icon-view"
                  class="afterBtn"
                  @click="afterDetisl(item.refundId)"
                  v-if="item.refundStatus != 0 && item.refundStatus != 4"
                  type="text"
                  size="small"
                  >售后详情</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="code" min-width="150" />
        <el-table-column
          align="center"
          label="用户昵称"
          prop="nickname"
          min-width="100"
        />
        <el-table-column
          align="center"
          label="推广员"
          prop="extensionName"
          min-width="150"
        />
        <el-table-column align="center" label="订单状态" min-width="130">
          <template slot-scope="scope">
            <div>{{ state[scope.row.status] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="下单时间"
          prop="orderTime"
          min-width="150"
        />
        <el-table-column
          label="买家留言"
          prop="remark"
          min-width="200"
          align="center"
        />
        <el-table-column
          label="卖家备注"
          prop="reply"
          min-width="200"
          align="center"
        />
        <el-table-column align="center" label="支付类型" min-width="100">
          <template slot-scope="scope">
            <span>{{ payTypes[scope.row.payType] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="订单金额"
          prop="orderAmount"
          min-width="100"
        />
        <el-table-column
          align="center"
          label="实付金额(元）"
          prop="payAmount"
          min-width="100"
        />
        <el-table-column
          align="center"
          label="积分抵扣(元)"
          prop="scoreAmount"
          min-width="100"
        />
        <el-table-column
          align="center"
          label="使用积分"
          prop="score"
          min-width="100"
        />
        <el-table-column
          align="center"
          label="收货人姓名"
          prop="shippingName"
          min-width="150"
        />
        <el-table-column
          align="center"
          label="收货人电话"
          prop="shippingMobile"
          min-width="150"
        />
        <el-table-column align="center" label="收货人地址" min-width="250">
          <template slot-scope="scope">
            <span>{{
              scope.row.shippingProvince +
              scope.row.shippingCity +
              scope.row.shippingDistrict +
              scope.row.shippingAddress
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-view"
              type="text"
              size="small"
              @click="onOrderDetails(scope.row.id)"
              >详情</el-button
            >
            <el-button
              icon="el-icon-view"
              type="text"
              size="small"
              @click="evaluate(scope.row.id)"
              >查看评价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.limit"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <!--查看评价-->
    <el-dialog title="查看评价" :visible.sync="dialogVisible" width="1000px">
      <EvaluateTable v-if="dialogVisible" :orderId="orderId" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  ordersPageList,
  ordersCount,
  ordersDetail,
  orderShipping,
} from "@/api/order/order";
import timeOptions from "@/assets/utils/timeOptions";
import { exportExcel } from "@/assets/utils/exportExcel";
import { state } from "../order/orders";
import EvaluateTable from "./evaluateTable";
import moment from "moment";
export default {
  name: "storeOrderlist",
  data() {
    return {
      state,
      pickerOptions: timeOptions,
      dialogVisible: false,
      drawer: false,
      orderId: "",
      tableData: {
        total: 0,
      },
      listLoading: false,
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        goodsName: "",
        mobile: "",
        orderCode: "",
        shippingName: "",
        status: "",
        userName: "",
        time: [],
        refundCode: "",
        extensionName: "",
      },
      payTypes: {
        1: "微信支付",
        2: "支付宝支付",
        3: "积分支付",
        4: "余额支付",
        5: "积分+线上支付",
        6: "余额+线上支付",
        7: "余额+积分支付",
        8: "积分+余额+线上支付",
        9: "免支付",
      },
      Count: {
        count: 0,
        count0: 0,
        count10: 0,
        count11: 0,
        count20: 0,
        count30: 0,
        count40: 0,
        count90: 0,
        count100: 0,
      },
    };
  },
  components: {
    EvaluateTable,
  },
  mounted() {
    this.getList();
    this.getOrdersCount();
  },
  methods: {
    evaluate(id) {
      this.dialogVisible = true;
      console.log("123", id);
      this.orderId = id;
    },
    getOrdersCount() {
      ordersCount().then((res) => {
        this.Count = res.data;
        // this.tableData.total = res.data
      });
    },
    afterDetisl(id) {
      this.$store.commit("setKeepList", ["storeOrderlist"]);
      this.$router.push({
        path: "/order/returnOrderInfo",
        query: {
          orderId: id,
        },
      });
    },
    //导出
    exportFun() {
      exportExcel("/order/web/orders/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "订单列表",
      });
    },
    typeChange() {
      this.getList();
      console.log("typeChange");
    },
    /**重置 */
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    closeDrawer() {
      this.drawer = false;
      this.userDawer = false;
    },
    changeDrawer(v) {
      this.drawer = v;
      this.userDawer = v;
    },
    /**订单列表 */
    downData(excelData) {
      return new Promise((resolve, reject) => {
        exportOrderApi(excelData).then((res) => {
          return resolve(res.data);
        });
      });
    },
    // 列表
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }

      ordersPageList({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    onOrderDetails(id) {
      this.$store.commit("setKeepList", ["storeOrderlist"]);
      this.$router.push({
        path: "/order/details",
        query: {
          id: id,
        },
      });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/form.scss";

.check {
  color: #00a2d4;
}

.dia {
  /deep/ .el-dialog__body {
    height: 700px !important;
  }
}

.text-right {
  text-align: right;
}

.minwidth {
  display: inline-block;
  max-width: 200px;
  line-height: 20px;
}

.search-form {
  display: flex;
  justify-content: space-between;

  .search-form-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  a {
    color: var(--prev-color-primary);
  }
}

.selWidth {
  width: 250px !important;
}

.search-form-sub {
  display: flex;
}

.container {
  min-width: 821px;

  /deep/.el-form-item {
    width: 100%;
  }

  /deep/.el-form-item__content {
    width: 72%;
  }
}

.vipName {
  color: #dab176;
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--prev-color-primary);
  font-size: 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}

/deep/.el-date-editor.el-input {
  width: 100%;
}

/deep/[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none !important;
}

/deep/.el-input-group__prepend .el-input {
  width: 100px;
}

.orderItems {
  padding: 20px;
}

.item {
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 10px;
  position: relative;
  // align-items: center;
}
.item_img {
  width: 86rpx;
  height: 86px;
  margin-right: 10px;
  border-radius: 5px;
}
.item_name {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}
.supplier {
  font-weight: 600;
}
.item_price {
  font-weight: 500;
  font-size: 12px;
  color: #e93323;
  margin-bottom: 5px;
}
.item_num {
  margin-bottom: 5px;
  font-size: 12px;
}
.afterBtn {
  position: absolute;
  top: 0;
  right: 50px;
}
</style>
